import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { colorForCloseDeals, convertObjIntoArray, getDashboardName, getMemberErrorMessages, getStartAndEndDates, getToken, getYearMonthDate, removeAllToken, setToken } from "../../../components/src/commonUsage";
import { trackEvent } from "../../../components/src/analytics";
import { getStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";

interface FeedbackQuestionList{
  "id": string,
  "type": string,
  "attributes": {
    "id": number | string,
    "content": string,
    "field_type": "Input" | "Text" | "Rating",
    "place_holder": string | null,
    "is_header": boolean,
    "is_required": boolean,
  }
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  emptyState: boolean;
  calendarMenu: any;
  calanderValue: any;
  dateRangeValue: any;
  openCalendar: any;
  addMemberModal: boolean;
  firstTimeUserModal: boolean;
  sliderIndex: number;
  leads_graph:any;
  maxVideo:boolean;
  dateValue:any;
  feedbackModal: boolean;
  feedbackQuestionList: FeedbackQuestionList[],
  initialValuesFeedback:{[key:string]:string},
  tabValue: any;
  feedBackResModal:boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiFeedbackApi: string = "";
  feedbackQuestionApi: string = "";
  profileApiCallId: string = "";
  formikRef: any = createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: {
        "partner": {
          name: "",
          partner_image: {
            url: ""
          }
        },
        "total_deals": [
          {
            "name": "total leads",
            "count": 0
          }
        ],
        "leads_graph": [
          {
            "name": "Closed won",
            "count": 0
          },
          {
            "name": "Closed lost",
            "count": 0
          },
          {
            "name": "Open",
            "count": 0
          },
          {
            "name": "Disqualified",
            "count": 0
          },
          {
            "name": "In progress",
            "count": 0
          }
        ],
        "close_graph_without_date_range": [],
        "total_leads_graph": [],
        "conversion_value": 0,
        "value_in_paipeline": 0,
        "commission_earned": 0,
        "total_closed_won_count": 0,
        "total_lead_closed_won_graph": [],
        "min_commit_value": 0,
        "over_all_closed_conversion_count": 0,
        "previous_conversion_value": 0,
        "previous_total_closed_won_count": 0,
        "previous_total_deals": 0,
      },
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      emptyState: true,
      calendarMenu: null,
      calanderValue: 'Last 90 days',
      dateRangeValue: getStartAndEndDates('Last 90 days'),
      openCalendar: false,
      addMemberModal: false,
      firstTimeUserModal: false, // enable for onboard flow
      sliderIndex: 1,
      leads_graph: {
        "Open":{name:"Leads",count:0},
        "Terms & Pricing":{name:"Terms and pricing",count:0},
        "SQL":{name:"Leads",count:0},
        "Verbal Agreement":{name:"Verbal agreement",count:0},
        "Discovery & Specification":{name:"Discovery and specification",count:0},
        "Closed won":{name:"Closed won",count:0},
        "Solution":{name:"Solution",count:0},
        "Closed lost":{name:"Closed lost",count:0},
        "Proposal": {name:"Proposal",count:0},
        "Disqualified":{name:"Disqualified",count:0},
        "Scope Discussion":{name:"Scope discussion",count:0},
    },
    maxVideo:false,
    dateValue:[],
    feedbackModal: false,
    feedbackQuestionList: [],
    initialValuesFeedback:{},
    tabValue: 0,
    feedBackResModal:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getFilteredData('normal')
    if (Number(getToken('login_count') || 0) === 0 && getToken('email')) {
      this.setState({
        firstTimeUserModal: true,
        sliderIndex:Number(getToken('currentSlide') || 1)
      })
      addEventListener("beforeunload", (event) => {
        setToken('currentSlide', this.state.sliderIndex ||1)
        return "leaved the page"
      });
    }
    trackEvent("login_successful","user logins the PRM portal",{dewey_code:"B3.1",flow:1})
    this.fetchQuestionList()
    this.getUserProfileInfo()
    addEventListener("beforeunload", (event) => {
      this.handleFeedbackFormSubmit(this.formikRef.current.values)
    })
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (getMemberErrorMessages(webResponseJson)) {
        removeAllToken();
        this.props.navigation.navigate('EmailAccountLoginBlock');
      } else if (!webResponseJson.errors) {
        
        this.dashboardApiResponse(webApiRequestCallId,webResponseJson)
        
        this.feedbackQuestionApiRes(webApiRequestCallId,webResponseJson)

        this.profileApiRes(webApiRequestCallId,webResponseJson)

        this.feedbackApiRes(webApiRequestCallId,webResponseJson)
      } else {
        this.setState({
          emptyState: true,
          loading: false
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleSliderIndex = (index: any) => {
    let newIndex;
    if (index === 'next') {
      newIndex = this.state.sliderIndex < 4 ? this.state.sliderIndex + 1 : 1;
    } else if (index === 'prev') {
      newIndex = this.state.sliderIndex > 1 ? this.state.sliderIndex - 1 : 4;
      this.setState({maxVideo:false})
    } else {
      newIndex = index;
    }
    this.setState({ sliderIndex: newIndex });
  };
  handleCalendarClick = (event: any) => {
    if (event) {
      event.persist();
      this.setState({ calendarMenu: event.currentTarget,openCalendar:false });
    }
  };
  handleCalendarClose = () => {
    this.setState({ calendarMenu: null });
  }
  handleCalendarSelect = (text: any) => {
    if (text === "Custom...") {
      if (this.state.calanderValue === 'Custom...') {
        this.setState({
          openCalendar: true,
          calanderValue: text,
          calendarMenu: null,
        })
      } else {
        this.setState({
          openCalendar: true,
          calanderValue: text,
          calendarMenu: null,
        })
      }

    } else if (text === "All Time") {
      this.setState({ calanderValue: text, calendarMenu: null, openCalendar: false, dateValue:[], dateRangeValue:[] }, () => {
        this.getFilteredData('allTime')
      });
    } else {
      this.setState({ calanderValue: text, calendarMenu: null, dateRangeValue: getStartAndEndDates(text), openCalendar: false, dateValue:[] }, () => {
        this.getFilteredData('normal')
      });
    }
  };
  handleDateChange = (item: any) => {
    this.setState({ dateValue: item });
  };
  getFilteredData = (value: any) => {
    this.setState({ loading: true })
    
    const uri=document.location.href
    const token=new URL(uri).searchParams.get("token")
    
    const webHeader = {
      token: token??getToken('authToken')
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    if (value === 'normal') {
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${webConfigJSON.dashboardGetUrl}?start_date=${getYearMonthDate(this.state.dateRangeValue[0])}&end_date=${getYearMonthDate(this.state.dateRangeValue[1])}`
      );
    }
    if (value === 'allTime') {
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        webConfigJSON.dashboardGetUrl
      );
    }

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }
  closeCalander = () => {
    this.setState({
      openCalendar: false
    })
  }
  navigateToLeadManagement = () => {
    this.props.navigation.navigate(
      "RegisterReferral"
    )
  }
  navigateToLeadDashboard = () => {
    this.props.navigation.navigate('LeadDashboard')
  }
  navigateToCommissionDashboard = () => {
    this.props.navigation.navigate('Commissionreports2')
  }
  openWelcomeModal() {
    this.setState({
      addMemberModal: true
    })
  }
  closeModal() {
    setToken('login_count', 1)
    localStorage.removeItem('currentSlide')
    this.setState({
      addMemberModal: false,
      firstTimeUserModal: false
    })
  }
  pieDataMapper = (data: any) => {
    const mappedKeysForPie = this.state.leads_graph
    data?.leads_graph?.map((item: any) => {
      if (mappedKeysForPie[item.name]) {
        mappedKeysForPie[item.name] = { ...item }
      }
    })

    this.setState({
      leads_graph: mappedKeysForPie
    })
  }

  navigateToNewDeal = () => {
    this.props.navigation.navigate('RegisterReferral')
  }

  openFeedbackModal=()=> {
    this.setState({
      feedbackModal: true,
    });
  }
  closeFeedbackModal=()=> {
    this.handleFeedbackFormSubmit(this.formikRef.current.values)
    this.setState({
      feedbackModal: false,
    })
  }

  getFeedbackFirstQuestion=()=>{
    const headerQuestion=this.state.feedbackQuestionList.filter(x=>x.attributes.is_header)
    if(headerQuestion.length)
      return headerQuestion[0]

    return {
      "id": "0",
      "type": "question",
      "attributes": {
          "id": 0,
          "content": "Head Missing",
          "field_type": "Rating",
          "place_holder": null,
          "is_header": true,
          "is_required": false
      }
  }
  }

  fetchQuestionList=async()=>{
    const webHeader = {
      token: await getStorageData('authToken')
    };

    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.feedbackQuestionApi = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        webConfigJSON.questionApi
      );
    
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true
  }

  handleFeedbackFormSubmit=async(values:{[key:string]:string},maybe_later=true)=>{
    const webHeader = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: await getStorageData('authToken')
    };

    const questionData=Object.keys(values).map(x=>({question_id:x,content:values[x].toString()}))[0]
    const payload={
      data:!maybe_later?questionData:{},
      maybe_later
    }
    
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFeedbackApi = webRequestMessage.messageId;
    
      webRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        webConfigJSON.questionApi
      );
    
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.postMethod
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
  }

  getUserProfileInfo = async () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: getToken('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.parnterShowEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  disableFeedbackForm=()=>{
    if(this.formikRef.current){
      const isField=this.formikRef.current.values[this.getFeedbackFirstQuestion().id]
      if(!isField){
        return true
      }
      return false
    }
    return false
  }

  profileApiRes=(webApiRequestCallId:string,webResponseJson:any)=>{
    if(this.profileApiCallId===webApiRequestCallId){
      const showFeedbackForm=webResponseJson.partner.data.attributes.preferences?.show_feedback
      if(showFeedbackForm){
        setTimeout(()=>{
          this.openFeedbackModal()
        },800)
      }
    }
  }

  feedbackApiRes=(webApiRequestCallId:string,webResponseJson:any)=>{
    if(this.apiFeedbackApi===webApiRequestCallId){
      const success=webResponseJson?.message
      if(success==="Feedback form successfully submitted."){
        this.setState({feedbackModal:false,feedBackResModal:true})
      }
    }
  }

  feedbackQuestionApiRes=(webApiRequestCallId:string,webResponseJson:any)=>{
    if(this.feedbackQuestionApi===webApiRequestCallId){
      const feedbackField:{[key:string]:string}={}
      webResponseJson.data.forEach((x:any)=>{
        feedbackField[x.id]=""
      })
      this.setState({
        initialValuesFeedback:feedbackField,
        feedbackQuestionList:webResponseJson.data as FeedbackQuestionList[]
      })
    }
  }

  handleTabChange(newValue: any) {
    this.setState({ tabValue: newValue })
  }

  handleConfirmationModalClose=()=>{
    this.setState(prevState=>({
      feedBackResModal:!prevState.feedBackResModal
    }))
  }

  dashboardApiResponse=(webApiRequestCallId:string,webResponseJson:any)=>{
    if (webApiRequestCallId === this.apiDashboardItemCallId) {
     
      const upatedRes:any={...webResponseJson}
      upatedRes.total_leads_graph=convertObjIntoArray(upatedRes.total_leads_graph)
      upatedRes.close_won_garph_with_date_range=convertObjIntoArray(upatedRes.close_won_garph_with_date_range)
      upatedRes.close_graph_without_date_range=convertObjIntoArray(upatedRes.close_graph_without_date_range)
      upatedRes.all_time_graph=convertObjIntoArray(upatedRes.all_time_graph)
      this.pieDataMapper(upatedRes)
      
      let dashboardData= upatedRes
      if(!dashboardData.empty_state){
        dashboardData.closed_deal_revenue=webResponseJson.closed_deal_revenue.map((deal:any,indice:number)=>{
          deal.color=colorForCloseDeals[indice];
          return deal
        })
        dashboardData.industry_closed_deal_revenue=dashboardData.industry_closed_deal_revenue.map((deal:any,indice:number)=>{
          deal.color=colorForCloseDeals[indice];
          return deal
        })

      let closedDeals=dashboardData.close_won_garph_with_date_range
      let totalDeals=dashboardData.total_leads_graph
      
      const closeDealsDates:{[key:string]:number}={}
      closedDeals.forEach((item:any)=>{
        const date=item.date
        if(date)
        closeDealsDates[date]=item.count
      })

      const totalDealsDates:{[key:string]:number}={}
      totalDeals.forEach((item:any)=>{
        const date=item.date
        if(date)
        totalDealsDates[date]=item.count
      })

      if(closedDeals.length<totalDeals.length){
        Object.keys(totalDealsDates).forEach(date=>{
          if(!closeDealsDates[date]){
            closeDealsDates[date]=0
          }
        })
      }else{
        Object.keys(closeDealsDates).forEach(date=>{
          if(!totalDealsDates[date]){
            totalDealsDates[date]=0
          }
        })
      }

      Object.keys(closeDealsDates).forEach(date=>{
        if(closeDealsDates[date]===0 && totalDealsDates[date]===0){
          delete closeDealsDates[date]
          delete totalDealsDates[date]
        }
      })

      closedDeals=Object.keys(closeDealsDates).map(date=>{
        return{date,count:closeDealsDates[date]}
      })
      totalDeals=Object.keys(totalDealsDates).map(date=>{
        return{date,count:totalDealsDates[date]}
      })
      dashboardData.labelDate=Object.keys(totalDealsDates).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    
      dashboardData.close_won_garph_with_date_range=closedDeals.sort((a:any, b:any) => new Date(a.date).getTime() - new Date(b.date).getTime())
      dashboardData.total_leads_graph=totalDeals.sort((a:any, b:any) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }
      
      this.setState({
        dashboardData,
        loading: false,
        openCalendar: false,
        emptyState: webResponseJson.empty_state
      });
      setToken('profile_name', getDashboardName(webResponseJson))
      setToken('notify_me', this.state.dashboardData?.partner.notify_me)
    }

  }
  // Customizable Area End
}
